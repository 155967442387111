import {
    SET_USER_DATA_START,
    SET_USER_DATA_SUCCESS,
    USER_LOGOUT_SUCCESS,
    PROFILE_PICTURE_LOADER_START,
    UPLOAD_PROFILE_PICTURE_SUCCESS,
    UPLOAD_PROFILE_PICTURE_ERROR,
    REMOVE_PROFILE_PICTURE_SUCCESS,
    REMOVE_PROFILE_PICTURE_ERROR,
    GET_USER_START,
    GET_USER_SUCCESS,
    GET_USER_ERROR
  } from '../constants';
  
  export default function userAvailability(state = {}, action) {
    switch (action.type) {
        case GET_USER_START:
          return {
            ...state,
          };
        case GET_USER_SUCCESS:
          return {
            ...state,
            data: action.payload.userStatus,
          };
        case GET_USER_ERROR:
          return {
            ...state,
            data: action.payload.userStatus,
          };
  
      default:
        return state;
    }
  }
  