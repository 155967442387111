import {
    LIST_SETTINGS_START,
    LIST_SETTINGS_ERROR,
    LIST_SETTINGS_SUCCESS
} from '../../constants';

export default function adminListSettingsData(state = {}, action) {
    switch (action.type) {
        case LIST_SETTINGS_START:
            return {
                ...state,
                loading: true
            }
        case LIST_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                currentPage: action.payload.currentPage
            };
        case LIST_SETTINGS_ERROR:
            return {
                loading: false,
                currentPage: 1
            };
        default:
            return state;
    }
}
