// The top-level (parent) route
export default {

  path: '/',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '/',
      load: () => import(/* webpackChunkName: 'home' */ './home')
    },
    {
      path: '/contact',
      load: () => import(/* webpackChunkName: 'contact' */ './contact')
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './login')
    },
    {
      path: '/register',
      load: () => import(/* webpackChunkName: 'register' */ './register')
    },
    {
      path: '/user/edit',
      load: () => import(/* webpackChunkName: 'editProfile' */ './editProfile')
    },
    {
      path: '/admin',
      load: () => import(/* webpackChunkName: 'admin' */ './admin')
    },
    {
      path: '/users/show/:profileId?',
      load: () => import(/* webpackChunkName: 'profile' */ './profile')
    },
    {
      path: '/become-a-host/:listId?/:formPage?',
      load: () => import(/* webpackChunkName: 'becomeHost' */ './becomeHost')
    },
    {
      path: '/space/:listId/:preview?',
      load: () => import(/* webpackChunkName: 'viewListing' */ './viewListing')
    },
    {
      path: '/space',
      load: () => import(/* webpackChunkName: 'manageListing' */ './manageListing')
    },
    {
      path: '/s',
      load: () => import(/* webpackChunkName: 'search' */ './search')
    },
    {
      path: '/user/photo',
      load: () => import(/* webpackChunkName: 'profilePhoto' */ './profilePhoto')
    },
    {
      path: '/user/verification',
      load: () => import(/* webpackChunkName: 'trustAndVerification' */ './trustAndVerification')
    },
    {
      path: '/users/security',
      load: () => import(/* webpackChunkName: 'changePassword' */ './changePassword')
    },
    {
      path: '/dashboard',
      load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard')
    },
    {
      path: '/inbox',
      load: () => import(/* webpackChunkName: 'inbox' */ './inbox')
    },
    {
      path: '/message/:threadId/:type',
      load: () => import(/* webpackChunkName: 'viewMessage' */ './viewMessage')
    },
    {
      path: '/book/:hostingId',
      load: () => import(/* webpackChunkName: 'book' */ './book')
    },
    {
      path: '/user/payout',
      load: () => import(/* webpackChunkName: 'payout' */ './payout')
    },
    {
      path: '/user/addpayout',
      load: () => import(/* webpackChunkName: 'addPayout' */ './addPayout')
    },
    {
      path: '/users/bookings/itinerary/:reservationId',
      load: () => import(/* webpackChunkName: 'itinerary' */ './itinerary')
    },
    {
      path: '/users/bookings/receipt/:reservationId',
      load: () => import(/* webpackChunkName: 'receipt' */ './receipt')
    },
    {
      path: '/reservation/:type',
      load: () => import(/* webpackChunkName: 'reservation' */ './reservation')
    },
    {
      path: '/bookings/:type',
      load: () => import(/* webpackChunkName: 'trips' */ './trips')
    },
    {
      path: '/user/transaction',
      load: () => import(/* webpackChunkName: 'transaction' */ './transaction')
    },
    {
      path: '/warning',
      load: () => import(/* webpackChunkName: 'warning' */ './warning')
    },
    {
      path: '/cancel/:reservationId/:type',
      load: () => import(/* webpackChunkName: 'cancel' */ './cancel')
    },
    {
      path: '/cancellation-policies/:type?',
      load: () => import(/* webpackChunkName: 'cancellationPolicies' */ './cancellationPolicies')
    },
    {
      path: '/user/reviews',
      load: () => import(/* webpackChunkName: 'reviews' */ './reviews')
    },
    {
      path: '/review/write/:reservationId',
      load: () => import(/* webpackChunkName: 'writeReview' */ './writeReview')
    },
    {
      path: '/password/verification',
      load: () => import(/* webpackChunkName: 'passwordVerification' */ './passwordVerification')
    },
    {
      path: '/userbanned',
      load: () => import(/* webpackChunkName: 'userbanned' */ './userbanned')
    },
    {
      path: '/user/payout/failure',
      load: () => import(/* webpackChunkName: 'addPayoutFailure' */ './addPayoutFailure')
    },
    {
      path: '/activity/:activityValue',
      load: () => import(/* webpackChunkName: 'activity' */ './activity')
    },
    {
      path: '/location/:location',
      load: () => import(/* webpackChunkName: 'location' */ './location')
    },
    {
      path: '/payment/:reservationId',
      load: () => import(/* webpackChunkName: 'location' */ './payment')
    },

    //document upload
    {
      path: '/document-verification',
      load: () => import(/* webpackChunkName: 'documentVerification' */ './documentVerification')
    },

    //blog
    {
      path: '/page/:u1?',
      load: () => import(/* webpackChunkName: 'blog' */ './blog')
    },
    {
      path: '/siteadmin/content-management',
      load: () => import(/* webpackChunkName: 'siteadmin-blogManagement' */ './siteadmin/blogManagement')
    },
    {
      path: '/siteadmin/page/add',
      load: () => import(/* webpackChunkName: 'siteadmin-addBlogDetails' */ './siteadmin/addBlogDetails')
    },
    {
      path: '/siteadmin/edit/page/:blogId',
      load: () => import(/* webpackChunkName: 'siteadmin-editBlogDetails' */ './siteadmin/editBlogDetails')
    },

    //why-host
    {
      path: '/siteadmin/why-host/block1',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock1' */ './siteadmin/whyHostPageSettings/whyHostBlock1')
    },
    {
      path: '/siteadmin/why-host/block2',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock2' */ './siteadmin/whyHostPageSettings/whyHostBlock2')
    },
    {
      path: '/siteadmin/why-host/block3',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock3' */ './siteadmin/whyHostPageSettings/whyHostBlock3')
    },
    {
      path: '/siteadmin/why-host/block4',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock4' */ './siteadmin/whyHostPageSettings/whyHostBlock4')
    },
    {
      path: '/siteadmin/why-host/block5',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock5' */ './siteadmin/whyHostPageSettings/whyHostBlock5')
    },
    {
      path: '/siteadmin/why-host/block6',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock6' */ './siteadmin/whyHostPageSettings/whyHostBlock6')
    },
    {
      path: '/siteadmin/why-host/block7',
      load: () => import(/* webpackChunkName: 'siteadmin-whyHostBlock7' */ './siteadmin/whyHostPageSettings/whyHostBlock7')
    },
    {
      path: '/siteadmin/payment-gateway-section',
      load: () => import(/* webpackChunkName: 'siteadmin-paymentGateway' */ './siteadmin/paymentGateway')
    },

    //ManagePayout
    {
      path: '/siteadmin/payout',
      load: () => import(/* webpackChunkName: 'siteadmin-managePayout' */ './siteadmin/managePayout')
    },
    {
      path: '/siteadmin/failed-payout/:id',
      load: () => import(/* webpackChunkName: 'siteadmin-failedPayout' */ './siteadmin/failedPayout')
    },

    // Static Pages
    {
      path: '/about',
      load: () => import(/* webpackChunkName: 'static-about' */ './static/about')
    },
    {
      path: '/privacy',
      load: () => import(/* webpackChunkName: 'static-privacy' */ './static/privacy')
    },
    {
      path: '/help',
      load: () => import(/* webpackChunkName: 'static-help' */ './static/help')
    },
    {
      path: '/safety',
      load: () => import(/* webpackChunkName: 'static-trustAndSafety' */ './static/trustAndSafety')
    },
    {
      path: '/travel',
      load: () => import(/* webpackChunkName: 'static-travelCredit' */ './static/travelCredit')
    },
    {
      path: '/whylistyourspace',
      load: () => import(/* webpackChunkName: 'whyhostnew' */ './whyhostnew')
    },
    {
      path: '/cookie-policy',
      load: () => import(/* webpackChunkName: 'static-cookiePolicy' */ './static/cookiePolicy')
    },

    // Add Admin Panel Pages Here
    {
      path: '/siteadmin',
      load: () => import(/* webpackChunkName: 'siteadmin-adminDashboard' */ './siteadmin/adminDashboard')
    },
    {
      path: '/siteadmin/login',
      load: () => import(/* webpackChunkName: 'siteadmin-adminLogin' */ './siteadmin/adminLogin')
    },
    {
      path: '/siteadmin/change/admin',
      load: () => import(/* webpackChunkName: 'siteadmin-changeAdmin' */ './siteadmin/changeAdmin')
    },
    {
      path: '/siteadmin/user/edit/:profileId',
      load: () => import(/* webpackChunkName: 'siteadmin-edituser' */ './siteadmin/edituser')
    },
    {
      path: '/siteadmin/users',
      load: () => import(/* webpackChunkName: 'siteadmin-users' */ './siteadmin/users')
    },
    {
      path: '/siteadmin/settings/site',
      load: () => import(/* webpackChunkName: 'siteadmin-siteSettings' */ './siteadmin/siteSettings')
    },
    {
      path: '/siteadmin/listsettings/:typeId',
      load: () => import(/* webpackChunkName: 'siteadmin-listSettings' */ './siteadmin/listSettings')
    },
    {
      path: '/siteadmin/listings',
      load: () => import(/* webpackChunkName: 'siteadmin-listings' */ './siteadmin/listings')
    },
    {
      path: '/siteadmin/currency',
      load: () => import(/* webpackChunkName: 'siteadmin-currencies' */ './siteadmin/currencies')
    },
    {
      path: '/siteadmin/settings/payment',
      load: () => import(/* webpackChunkName: 'siteadmin-paymentSettings' */ './siteadmin/paymentSettings')
    },
    // {
    //   path: '/siteadmin/settings/search',
    //   load: () => import(/* webpackChunkName: 'siteadmin-searchSettings' */ './siteadmin/searchSettings')
    // },
    {
      path: '/siteadmin/home/caption',
      load: () => import(/* webpackChunkName: 'siteadmin-bannerSettings' */ './siteadmin/bannerSettings')
    },
    {
      path: '/siteadmin/home/banner',
      load: () => import(/* webpackChunkName: 'siteadmin-imageBanner' */ './siteadmin/imageBanner')
    },
    {
      path: '/siteadmin/reservations',
      load: () => import(/* webpackChunkName: 'siteadmin-reservations' */ './siteadmin/reservations')
    },
    {
      path: '/siteadmin/receipt/:reservationId',
      load: () => import(/* webpackChunkName: 'siteadmin-viewReceipt' */ './siteadmin/viewReceipt')
    },
    {
      path: '/siteadmin/settings/servicefees',
      load: () => import(/* webpackChunkName: 'siteadmin-serviceFeesSettings' */ './siteadmin/serviceFeesSettings')
    },
    {
      path: '/siteadmin/reviews',
      load: () => import(/* webpackChunkName: 'siteadmin-adminReviews' */ './siteadmin/adminReviews')
    },
    {
      path: '/siteadmin/write-reviews',
      load: () => import(/* webpackChunkName: 'siteadmin-writeReview' */ './siteadmin/writeReview')
    },
    {
      path: '/siteadmin/edit-review/:reviewId',
      load: () => import(/* webpackChunkName: 'siteadmin-editReview' */ './siteadmin/editReview')
    },
    {
      path: '/siteadmin/viewreservation/:id/:type',
      load: () => import(/* webpackChunkName: 'siteadmin-viewreservation' */ './siteadmin/viewreservation')
    },
    {
      path: '/siteadmin/home/footer-block',
      load: () => import(/* webpackChunkName: 'siteadmin-footerBlock' */ './siteadmin/footerBlock')
    },
    {
      path: '/siteadmin/messages',
      load: () => import(/* webpackChunkName: 'siteadmin-messages' */ './siteadmin/messages')
    },
    {
      path: '/siteadmin/reportUser',
      load: () => import(/* webpackChunkName: 'siteadmin-reportUser' */ './siteadmin/reportUser')
    },
    {
      path: '/siteadmin/popularlocation',
      load: () => import(/* webpackChunkName: 'siteadmin-popularLocation' */ './siteadmin/popularLocation')
    },
    {
      path: '/siteadmin/edit/popularlocation/:locationId',
      load: () => import(/* webpackChunkName: 'siteadmin-editPopularLocation' */ './siteadmin/editPopularLocation')
    },
    {
      path: '/siteadmin/popularlocation/add',
      load: () => import(/* webpackChunkName: 'siteadmin-addPopularLocation' */ './siteadmin/addPopularLocation')
    },
    {
      path: '/siteadmin/staticpage/management',
      load: () => import(/* webpackChunkName: 'siteadmin-staticPage' */ './siteadmin/staticPage')
    },
    {
      path: '/siteadmin/edit/staticpage/:pageId',
      load: () => import(/* webpackChunkName: 'siteadmin-editStaticPage' */ './siteadmin/editStaticPage')
    },
    {
      path: '/siteadmin/home/static-info-block',
      load: () => import(/* webpackChunkName: 'siteadmin-staticBlock' */ './siteadmin/staticBlock')
    },
    {
      path: '/siteadmin/home/home-banner',
      load: () => import(/* webpackChunkName: 'siteadmin-homeBanner' */ './siteadmin/homeBanner')
    },
    {
      path: '/siteadmin/home/whychoose-section',
      load: () => import(/* webpackChunkName: 'siteadmin-whyChoose' */ './siteadmin/whyChoose')
    },
    {
      path: '/siteadmin/home/mobileapps-section',
      load: () => import(/* webpackChunkName: 'siteadmin-mobileApps' */ './siteadmin/mobileApps')
    },
    {
      path: '/siteadmin/cancellation/management',
      load: () => import(/* webpackChunkName: 'siteadmin-cancellation' */ './siteadmin/cancellation')
    },
    {
      path: '/siteadmin/edit/cancellation/:cancellationId',
      load: () => import(/* webpackChunkName: 'siteadmin-editCancellation' */ './siteadmin/editCancellation')
    },
    {
      path: '/siteadmin/home/activity-content',
      load: () => import(/* webpackChunkName: 'siteadmin-activitiesContentManagement' */ './siteadmin/activitiesContentManagement')
    },
    {
      path: '/siteadmin/home/location-content',
      load: () => import(/* webpackChunkName: 'siteadmin-locationContentManagement' */ './siteadmin/locationContentManagement')
    },
    {
      path: '/siteadmin/viewpayout/:id/:type',
      load: () => import(/* webpackChunkName: 'siteadmin-viewPayout' */ './siteadmin/viewPayout')
    },
    {
      path: '/siteadmin/activityType',
      load: () => import(/* webpackChunkName: 'siteadmin-activityType' */ './siteadmin/activityType')
    },

    //Profile View
    {
      path: '/siteadmin/profileView/:profileId?',
      load: () => import(/* webpackChunkName: 'siteadmin-profileView' */ './siteadmin/profileView')
    },

    //document view
    {
      path: '/siteadmin/document',
      load: () => import(/* webpackChunkName: 'siteadmin-document' */ './siteadmin/document')
    },
    {
      path: '/siteadmin/user-reviews',
      load: () => import(/* webpackChunkName: 'siteadmin-userReviews' */ './siteadmin/userReviews')
    },
    {
      path: '/siteadmin/management-reviews/:reviewId',
      load: () => import(/* webpackChunkName: 'siteadmin-userEditReviews' */ './siteadmin/userEditReviews')
    },

    //Admin users and roles
    {
      path: '/siteadmin/admin-roles',
      load: () => import(/* webpackChunkName: 'siteadmin-adminRoles' */ './siteadmin/adminRoles')
    },
    {
      path: '/siteadmin/admin-users',
      load: () => import(/* webpackChunkName: 'siteadmin-adminUser' */ './siteadmin/adminUser')
    },
    //Landing page
    {
      path: '/siteadmin/activity/image-Banner',
      load: () => import(/* webpackChunkName: 'siteadmin-activityPageBanner' */ './siteadmin/activityPageBanner')
    },
    {
      path: '/siteadmin/location/image-Banner',
      load: () => import(/* webpackChunkName: 'siteadmin-locationPageBanner' */ './siteadmin/locationPageBanner')
    },

    // Wish Lists
    {
      path: '/wishlists/:id?',
      load: () => import(/* webpackChunkName: 'wishLists' */ './wishLists')
    },
    // site Configuration Settings
    {
      path: '/siteadmin/settings/site-configuration',
      load: () => import(/* webpackChunkName: 'wishLists' */ './siteadmin/siteConfiguration')
    },
    //manage faq
    {
      path: '/siteadmin/manage-faq',
      load: () => import(/* webpackChunkName: 'siteadmin-faqManagement' */ './siteadmin/faqManagement')
    },
    {
      path: '/siteadmin/manage-faq/add',
      load: () => import(/* webpackChunkName: 'siteadmin-addFaq' */ './siteadmin/addFaq')
    },
    {
      path: '/siteadmin/manage-faq/edit/:faqId',
      load: () => import(/* webpackChunkName: 'siteadmin-editBlogDetails' */ './siteadmin/editFaq')
    },

    //manage category
    {
      path: '/siteadmin/manage-category',
      load: () => import(/* webpackChunkName: 'siteadmin-categoryManagement' */ './siteadmin/categoryManagement')
    },
    {
      path: '/siteadmin/manage-category/add',
      load: () => import(/* webpackChunkName: 'siteadmin-addCategory' */ './siteadmin/addCategory')
    },
    {
      path: '/siteadmin/manage-category/edit/:categoryId',
      load: () => import(/* webpackChunkName: 'siteadmin-editCategory' */ './siteadmin/editCategory')
    },

    //manage sub category
    {
      path: '/siteadmin/manage-subcategory',
      load: () => import(/* webpackChunkName: 'siteadmin-categoryManagement' */ './siteadmin/subCategoryManagement')
    },
    {
      path: '/siteadmin/manage-subcategory/add',
      load: () => import(/* webpackChunkName: 'siteadmin-addCategory' */ './siteadmin/addSubCategory')
    },
    {
      path: '/siteadmin/manage-subcategory/edit/:categoryId',
      load: () => import(/* webpackChunkName: 'siteadmin-editCategory' */ './siteadmin/editSubCategory')
    },

    //manage feedback
    {
      path: '/siteadmin/feedback',
      load: () => import(/* webpackChunkName: 'feedback' */ './siteadmin/feedback')
    },

    //view delete account request
    {
      path: '/siteadmin/account-deletion-request',
      load: () => import(/* webpackChunkName: 'feedback' */ './siteadmin/deleteRequest')
    },

    //view listing
        {
      path: '/siteadmin/viewlisting/:listId',
      load: () => import(/* webpackChunkName: 'siteadmin-listView' */ './siteadmin/listView')
    },
    // Wildcard routes, e.g. { path: '*', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'notFound' */ './notFound')
    },
  ],

  async action({ next }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return route;
  },

};
