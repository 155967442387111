require("dotenv").config();

export const port = process.env.PORT || 3000;
export const host = process.env.WEBSITE_HOSTNAME || `localhost:${port}`;
export const url = process.env.WEBSITE_URL || "http://localhost:3000";
export const sitename = process.env.SITENAME || "Your Website Name";
export const adminEmail = process.env.ADMIN_EMAIL || "admin@yourdomain.com";

export const adminEmaillist = [];

// default locale is the first one
export const locales = ["en-US", "es", "it-IT", "fr-FR", "pt-PT", "ar", "he"];

export const databaseUrl = process.env.DATABASE_URL;

// Listing Photos Upload Directory
export const fileuploadDir = process.env.FILEUPLOAD_DIR || "./images/upload/";

// Logo upload directory
export const logouploadDir = process.env.LOGOUPLOAD_DIR || "./images/logo/";

// Home page Banner upload directory
export const banneruploadDir =
  process.env.BANNER_UPLOAD_DIR || "./images/banner/";

// User Profile Photos Upload Directory
export const profilePhotouploadDir =
  process.env.PROFILE_PHOTO_UPLOAD_DIR || "./images/avatar/";

//Document Upload
export const documentuploadDir =
  process.env.FILEUPLOAD_DIR || "./images/document/";

// Location upload directory
export const locationuploadDir =
  process.env.LOGOUPLOAD_DIR || "./images/popularLocation/";

// Static block image upload directory
export const homebanneruploadDir =
  process.env.HOME_BANNER_UPLOAD_DIR || "./images/home/";

// Site Admin Icon image upload directory
export const iconUploadDir = process.env.ICONUPLOAD_DIR || "./images/icon/";

export const whyHostImageuploadDir =
  process.env.WHY_HOST_IMAGE_UPLOAD_DIR || "./images/whyhost/";

// Site Admin Cancel Image upload directory
export const cancellationDir =
  process.env.CANCELLATION_DIR || "./images/cancellation/";

// Favicon images update directory
export const faviconUploadDir = "./images/favicon/";

export const analytics = {
  // https://analytics.google.com/
  google: {
    trackingId: "UA-XXXXX-X",
  },
};

export const googleMapAPI = "<Your API Key>";
export const googleMapServerAPI = process.env.GOOGLE_MAP_SERVER_API;

export const payment = {
  paypal: {
    returnURL: `${url}/success`,
    cancelURL: `${url}/cancel`,
    redirectURL: {
      success: "/users/bookings/itinerary",
      cancel: "/contact",
    },
  },

  stripe: {
    secretKey: process.env.STRIPE_SECRET /* From ENV */,
    publishableKey: "pk_test_C5ukBJM7qr5P1F8dY4XKhdyp",
  },

  stripeSG: {
    secretKey: process.env.STRIPE_SECRET_SG,
  },

  stripeHK: {
    secretKey: process.env.STRIPE_SECRET_HK,
  },
};

// site key for google recaptcha
export const googleCaptcha = {
  sitekey: "<Your Site key>",
};

export const auth = {
  jwt: { secret: process.env.JWT_SECRET },

  redirectURL: {
    login: "/dashboard",
    verification: "/user/verification",
    userbanned: "/userbanned",
    returnURLDeletedUser: "/userbanned",
  },

  // https://developers.facebook.com/
  facebook: {
    returnURL: `${url}/login/facebook/return`,
  },

  // https://cloud.google.com/console/project
  google: {
    returnURL: `${url}/login/google/return`,
  },
};

export const s3Config = {
  accessKeyId: process.env.S3_ACCESS_KEY,
  secretAccessKey: process.env.S3_SECRET_KEY,
  region: process.env.S3_REGION,
  bucket: process.env.S3_BUCKET_NAME,
  url: process.env.S3_URL || "https://fastrent.s3.ap-southeast-1.amazonaws.com",
};

export const homepageUrl = process.env.HOMEPAGE_URL || "https://fastrentapp.io";
