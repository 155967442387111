import { CHECK_ACTIVITY_DATA_SUCCESS, CHECK_LIST_ACTIVITY_DATA_SUCCESS } from '../constants';

export default function checkActivity(state = {}, action) {
  switch (action.type) {
    case CHECK_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        status: action.status,
      };

    case CHECK_LIST_ACTIVITY_DATA_SUCCESS:
      return {
        ...state,
        checklistStatus: action.status,
      };
    default:
      return state;
  }
}
